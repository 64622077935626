import styled, { css } from "styled-components";

export const Input = styled.div`
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")}px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")}px;
  width: ${(p) => (p.width ? `${p.width}px` : "auto")};
  position: relative;

  label {
    transition: all 150ms ease-in-out;
    display: block;
    color: ${(p) => p.theme[p.$color]};
    color: ${(p) => (p.readonly ? p.theme.text : p.theme.text)};
    font-size: ${(p) => (p.small ? "12px" : "14px")};
    font-weight: 600;
    margin-bottom: 4px;
    /* white-space: nowrap;
    text-overflow: visible; */
  }

  .input {
    position: relative;
  }

  input {
    transition: all 150ms ease-in-out;
    display: block;
    width: 100%;
    font-size: ${(p) => (p.small ? "16px" : "18px")};
    box-sizing: border-box;
    background: var(--color-white);
    border-radius: 0 12px 0 12px;
    border: 2px solid
      ${(p) => (p.error ? p.theme.dangerLight : p.theme.input.border)};
    outline: none;
    padding: ${(p) =>
      p.$type === "password"
        ? "8px 40px 8px 16px"
        : p.small
        ? "4px 4px 4px 8px"
        : "8px 8px 8px 16px"};

    /* &[type=password] {
      padding: 8px 40px 8px 16px;
    } */

    &:valid {
      border: 2px solid ${(p) => (p.error ? p.theme.danger : p.theme[p.$color])};
      background: var(--color-white);
    }

    &:focus {
      box-shadow: ${(p) =>
        p.readonly ? "none" : p.theme.input.shadow[p.$color]};
      border: 2px solid ${(p) => p.theme[p.$color]};
      background: ${(p) => p.theme.input.bg};
    }
  }

  ${(p) =>
    p.readonly &&
    css`
      &&& label {
        color: ${(p) => p.theme.textLightGray};
      }

      &&& input {
        border: 2px solid ${(p) => p.theme.input.border};
        background: ${(p) => p.theme.input.border};
      }
    `}

  &:focus-within {
    label {
      font-weight: 600;
      color: ${(p) => p.theme[p.$color]};
    }
  }
`;

export const Error = styled.p`
  color: ${(p) => p.theme.danger};
  font-size: 14px;
  margin: 0px 4px;
  position: relative;
`;

export const ShowPass = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  background: none;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  color: ${(p) => p.theme.textDarkerGray};
  transition: all 200ms ease-in-out;

  &:hover {
    color: ${(p) => p.theme.text};
  }
`;
