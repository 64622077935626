import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import AuthService from "../../services/AuthService";
import GerenciaTokenCliente from "../../services/config/GerenciaTokenCliente";
import Modal from "../Modal";
import Button from "../Button";
import configData from "../../config.json";
import { store } from "react-notifications-component";
import { ToastNotification, notificationTopCenter } from "../ToastNotification";

import { ReactComponent as AcceptIcon } from "../../icons/accept-icon.svg";
import { ReactComponent as MSLogoIcon } from "../../icons/mssymbol.svg";
import { ReactComponent as GoogleIcon } from "../../icons/google-logo.svg";

import { FormInput } from "../FormInput";

import { useInput } from "../../hooks/useInput";

import DSButton from "../@DesignSystem/Button";
import DSLink from "../@DesignSystem/StyledLink";
import DSFormInput from "../@DesignSystem/FormInput";
import DSInput from "../@DesignSystem/Input";
import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: configData.masal_config.auth.clientId,
    authority: configData.masal_config.auth.authority,
    redirectUri: process.env.REACT_APP_REDIRECT_URI_SSO,
  },
  cache: {
    cacheLocation: configData.masal_config.cache.cacheLocation, // This configures where your cache will be stored
    storeAuthStateInCookie:
      configData.masal_config.cache.storeAuthStateInCookie, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
const loginRequest = {
  scopes: configData.masal_config.loginRequest.scopes,
};
const apiConfig = {
  scopes: configData.masal_config.apiConfig.scopes, // e.g. ["scp1", "scp2"]
};
const tokenRequest = {
  scopes: [...apiConfig.scopes],
};
const myMSALObj = new msal.PublicClientApplication(msalConfig);

const HeaderLogin = styled.h4`
  font-size: 1.5rem;
  text-align: left;
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  .align-left {
    align-self: flex-end;
  }

  a:last-child {
    text-align: center;

    img {
      height: 36px;
      margin-top: 1.5rem;
      text-align: center;
    }
  }

  span.divider {
    width: 100%;
    height: 2px;
    background-color: ${(p) => p.theme.input.border};
    margin: 32px 0;
  }
`;

const TwoFactorLogin = styled.div`
  h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  button {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

const RecuperaSenha = styled.div`
  h4 {
    font-size: 1.25rem;
  }

  p {
    margin-top: 1rem;
  }

  div.flexwrapper {
    button {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
`;

const SucessoSenha = styled.div`
  margin: 1rem 0;
`;

const SenhaCriada = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.successLight};
  border-radius: 8px;
  padding: 1.5rem;

  svg {
    height: 32px;
    width: 32px;
  }

  h5 {
    margin: 1.5rem 0;
    font-size: 1.2rem;
  }
`;

export function Login(props) {
  const [notFound, setNotFound] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [inactiveSale, setInactiveSale] = useState(false);
  const [tokenValue, setTokenValue] = useState("");
  const [twofactorModal, setTwofactorModal] = useState(false);
  const [wrongToken, setWrongToken] = useState(false);
  const [isModalRecuperaOpen, setIsModalRecuperaOpen] = useState(false);
  const [senhaRecuperada, setSenhaRecuperada] = useState(false);
  const [emailRecuperacaoError, setEmailRecuperacaoError] = useState(false);
  const [loginMsal, setLoginMsal] = useState(false);
  const [tokenMsal, setTokenMsal] = useState("");
  const [emailMsal, setEmailMsal] = useState("");
  const [msgErroSenha, setMsgErroSenha] = useState("");

  const textInput = useRef(null);
  const passInput = useRef(null);

  useEffect(() => {
    if (textInput.current) {
      textInput.current.focus();
    }
  }, []);

  const {
    value: email,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
  } = useInput("", "email");
  const {
    value: emailRecuperacao,
    isInvalid: isInvalidEmailRecuperacao,
    bind: bindEmailRecuperacao,
  } = useInput("", "email");
  const {
    value: token,
    isInvalid: isInvalidToken,
    bind: bindToken,
  } = useInput("", "", "vazio");
  const {
    value: password,
    isInvalid: isInvalidPassword,
    bind: bindPassword,
  } = useInput("", "", "vazio");

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleKeyUpToken = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      handleLogin2fa();
    }
  };

  const closeModal = () => {
    setTwofactorModal(false);
  };

  const toggleModalRecupera = () => {
    setIsModalRecuperaOpen(!isModalRecuperaOpen);
  };

  //------------------------- INICIO SINGLE SIGN ON ---------------------------------------------//
  const handleLoginMsal = () => {
    myMSALObj
      .loginPopup(loginRequest)
      .then(handleResponseMsal)
      .catch((error) => {
        console.error(error);
      });
  };

  const handleResponseMsal = (response) => {
    console.log(response);

    if (response !== null) {
      passTokenToLogin(response);
    } else {
      passTokenToLogin(selectAccount());
    }
  };

  const selectAccount = () => {
    const currentAccounts = myMSALObj.getAllAccounts();

    if (!currentAccounts || currentAccounts.length < 1) {
      return;
    } else if (currentAccounts.length > 1) {
      console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  };

  const passTokenToLogin = (msalParams) => {
    getTokenPopup(tokenRequest, msalParams.account.username).then(
      (response) => {
        if (response) {
          console.log("access_token acquired at: " + new Date().toString());
          setWrongPassword(false);
          setNotFound(false);
          AuthService.autenticarMsal(
            { email: response.account.username },
            response.accessToken
          )
            .then((res) => {
              if (res.data.token) {
                //salvar token e email aqui
                // console.dir(res);
                props.doLogin(res.data.token, res.data?.email);
                GerenciaTokenCliente.salvaBDBrowser(res.data.token);
              } else {
                setTokenMsal(response.accessToken);
                setEmailMsal(response.account.username);
                setLoginMsal(true);
                setTwofactorModal(true);
              }
            })
            .catch((err) => {
              console.log(err);
              let msg;
              if (err.response.status == 403) {
                msg =
                  "Erro ao autenticar usuário. Verifique se ele está confirmado e se possui autenticação com conta Microsoft/Google habilitada.";
              } else if (err.response.status == 404) {
                msg = "Usuário não encontrado.";
              } else {
                msg = "Erro ao realizar autenticação. Tente mais tarde.";
              }
              store.addNotification({
                content: (
                  <ToastNotification bg="danger" textoAuxiliar="">
                    {msg}
                  </ToastNotification>
                ),
                ...notificationTopCenter,
              });
            });
        }
      }
    );
  };

  const getTokenPopup = (request, username) => {
    request.account = myMSALObj.getAccountByUsername(username);

    return myMSALObj.acquireTokenSilent(request).catch((error) => {
      console.warn(error);
      console.warn(
        "silent token acquisition fails. acquiring token using popup"
      );
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return myMSALObj
          .acquireTokenPopup(request)
          .then((response) => {
            console.log(response);
            return response;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.warn(error);
      }
    });
  };

  //------------------------- FIM SINGLE SIGN ON ---------------------------------------------//

  const handleLogin = (e) => {
    setWrongPassword(false);
    setNotFound(false);
    let userObject = {
      Nome: "",
      email: email,
      senha: password,
    };

    AuthService.autenticar(userObject)
      .then((res) => {
        if (res.data.token) {
          //salvar token e email aqui
          // console.dir(res);
          props.doLogin(res.data.token, res.data?.email);
          GerenciaTokenCliente.salvaBDBrowser(res.data.token);
        } else {
          setLoginMsal(false);
          setTwofactorModal(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setMsgErroSenha(err.response.data.descricao);
        setWrongPassword(true);
        setNotFound(true);
        // if (err.response.status === 404) {
        //   if (
        //     err.response.data.descricao ===
        //     "O usuário não possui uma venda ativa"
        //   ) {
        //     setNotFound(false);
        //     setInactiveSale(true);
        //     setWrongPassword(false);
        //   } else {
        //     setNotFound(true);
        //     setInactiveSale(false);
        //     setWrongPassword(false);
        //   }
        // }
        // if (err.response.status === 400) {
        //   setNotFound(false);
        //   setInactiveSale(false);
        //   setWrongPassword(true);
        //   setMsgErroSenha("Usuário ou senha incorretos");
        // }
        // if (err.response.status === 403) {
        //   setNotFound(false);
        //   setInactiveSale(false);
        //   setWrongPassword(true);
        //   setMsgErroSenha(err.response.descricao);
        // }
      });
    //  this.props.handleLogin();
  };

  const handleLogin2fa = () => {
    // console.log("2Factor");
    setWrongToken(false);
    if (loginMsal) {
      let obj = {
        email: emailMsal,
        twofactortoken: token.replace(/\s+/g, ""),
      };
      AuthService.autenticar2faMsal(obj, tokenMsal)
        .then((res) => {
          if (res.data.token) {
            //salvar token e email aqui
            // console.dir(res);
            props.doLogin(res.data.token);
            GerenciaTokenCliente.salvaBDBrowser(res.data.token);
          }
        })
        .catch((err) => {
          // console.log("Teste de log");
          // console.log(err.response);
          if (err.response.status < 500) {
            setWrongToken(true);
          }
        });
    } else {
      let obj = {
        email: email,
        twofactortoken: token.replace(/\s+/g, ""),
        senha: password,
      };
      AuthService.autenticar2fa(obj)
        .then((res) => {
          if (res.data.token) {
            //salvar token e email aqui
            // console.dir(res);
            props.doLogin(res.data.token);
            GerenciaTokenCliente.salvaBDBrowser(res.data.token);
          }
        })
        .catch((err) => {
          // console.log("Teste de log");
          // console.log(err.response);
          if (err.response.status < 500) {
            setWrongToken(true);
          }
        });
    }
  };

  const recuperaSenha = () => {
    AuthService.recuperarSenha(emailRecuperacao)
      .then((resp) => {
        //console.log(resp);
        // this.setState({
        //   senhaRecuperada: true,
        //   emailRecuperacaoError: false,
        // });
        setSenhaRecuperada(true);
        setEmailRecuperacaoError(false);
      })
      .catch((err) => {
        // console.log('aaa', err);
        setEmailRecuperacaoError(true);
      });
  };

  return (
    <>
      <HeaderLogin>Login TaxDashs Cloud</HeaderLogin>
      {twofactorModal && (
        <Modal
          id="twofactor-modal"
          isOpen={twofactorModal}
          onClose={closeModal}
          modalSize="sm"
        >
          <TwoFactorLogin>
            <h4>Login em 2-fatores</h4>
            <p>Insira o token do Google Authenticator para entrar</p>
            <DSInput
              short
              inputRef={textInput}
              required
              type="token"
              id="token"
              label="Token"
              errorMsg="Token inválido"
              onKeyPress={handleKeyUpToken}
              error={wrongToken}
              placeholder="123 123"
              mt={16}
              mb={16}
              {...bindToken}
            >
              <DSButton background="primary" onClick={handleLogin2fa}>
                Entrar
              </DSButton>
            </DSInput>
          </TwoFactorLogin>
        </Modal>
      )}
      {isModalRecuperaOpen && (
        <Modal
          id="modal"
          isOpen={isModalRecuperaOpen}
          onClose={toggleModalRecupera}
          modalSize="md"
        >
          <RecuperaSenha>
            {!senhaRecuperada ? (
              <>
                <h4>Deseja recuperar sua senha?</h4>
                <p>Digite o email da conta que deseja recuperar a senha</p>
                <DSInput
                  required
                  type="text"
                  label="Email"
                  placeholder="nome@email.com"
                  errorMsg="Email não cadastrado. Tente com outro email."
                  error={emailRecuperacaoError}
                  mt={16}
                  mb={16}
                  {...bindEmailRecuperacao}
                >
                  <DSButton background="primary" onClick={recuperaSenha}>
                    Recuperar
                  </DSButton>
                </DSInput>
              </>
            ) : (
              <SucessoSenha>
                <SenhaCriada>
                  <AcceptIcon />
                  <h5>Senha recuperada com sucesso!</h5>
                  <p>
                    {/* Clique no link enviado ao email{" "}
                    <span className="email bold">{emailRecuperacao}</span> para
                    criar uma nova senha */}
                    Se o email estiver cadastrado na plataforma, você receberá
                    um link no endereço{" "}
                    <span className="email bold">{emailRecuperacao}</span> para
                    criar uma nova senha
                  </p>
                </SenhaCriada>
              </SucessoSenha>
            )}
          </RecuperaSenha>
        </Modal>
      )}
      <FormGroup>
        <DSFormInput
          inputRef={textInput}
          required
          type="email"
          name="email"
          label="Email"
          mt={24}
          // errorMsg={
          //   inactiveSale
          //     ? "O usuário não possui uma venda ativa"
          //     : "Email não cadastrado"
          // }
          onKeyUp={handleKeyUp}
          error={notFound || inactiveSale}
          {...bindEmail}
        />
        <DSFormInput
          inputRef={passInput}
          required
          type="password"
          id="senha"
          label="Senha"
          mt={24}
          errorMsg={msgErroSenha}
          onKeyUp={handleKeyUp}
          error={wrongPassword}
          {...bindPassword}
        />
        <DSLink
          mt={16}
          mb={16}
          className="align-left"
          onClick={toggleModalRecupera}
        >
          Esqueci minha senha
        </DSLink>
        <DSButton background="primary" onClick={handleLogin}>
          Entrar
        </DSButton>
        <span className="divider" />
        <DSButton
          ghost
          color="textDarkerGray"
          bg="gray"
          iconLeft
          onClick={handleLoginMsal}
        >
          <MSLogoIcon /> Entrar com Microsoft
        </DSButton>
        <DSButton
          ghost
          color="textDarkerGray"
          bg="gray"
          iconLeft
          mt={24}
          onClick={handleLoginMsal}
        >
          <GoogleIcon /> Entrar com Google
        </DSButton>
      </FormGroup>
    </>
  );
}
